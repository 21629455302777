(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
    typeof define === 'function' && define.amd ? define(factory) :
    (global.sessionStorageWrapper = factory());
}(this, (function () { 'use strict';

    const __assign = Object.assign || function (target) {
        for (var source, i = 1; i < arguments.length; i++) {
            source = arguments[i];
            for (var prop in source) {
                if (Object.prototype.hasOwnProperty.call(source, prop)) {
                    target[prop] = source[prop];
                }
            }
        }
        return target;
    };

    var getSerializerPromiseCache;
    function getSerializerPromise(localForageInstance) {
        if (getSerializerPromiseCache) {
            return getSerializerPromiseCache;
        }
        if (!localForageInstance ||
            typeof localForageInstance.getSerializer !== 'function') {
            return Promise.reject(new Error('localforage.getSerializer() was not available! ' +
                'localforage v1.4+ is required!'));
        }
        getSerializerPromiseCache = localForageInstance.getSerializer();
        return getSerializerPromiseCache;
    }
    function getCallback() {
        if (arguments.length &&
            typeof arguments[arguments.length - 1] === 'function') {
            return arguments[arguments.length - 1];
        }
    }
    function executeCallback(promise, callback) {
        if (callback) {
            promise.then(function (result) {
                callback(null, result);
            }, function (error) {
                callback(error);
            });
        }
        return promise;
    }
    function isSessionStorageValid() {
        try {
            return (typeof sessionStorage !== 'undefined' &&
                'setItem' in sessionStorage &&
                !!sessionStorage.setItem);
        }
        catch (e) {
            return false;
        }
    }
    function normalizeKey(key) {
        if (typeof key !== 'string') {
            console.warn(key + " used as a key, but it is not a string.");
            key = String(key);
        }
        return key;
    }

    function _getKeyPrefix(options, defaultConfig) {
        var keyPrefix = options.name + '/';
        if (options.storeName !== defaultConfig.storeName) {
            keyPrefix += options.storeName + '/';
        }
        return keyPrefix;
    }
    function checkIfStorageThrows(storage) {
        var storageTestKey = '_localforage_support_test';
        try {
            storage.setItem(storageTestKey, 'true');
            storage.removeItem(storageTestKey);
            return false;
        }
        catch (e) {
            return true;
        }
    }
    function _isStorageUsable(storage) {
        return !checkIfStorageThrows(storage) || storage.length > 0;
    }
    function _initStorage(options) {
        if (options === void 0) { options = {}; }
        var dbInfo = __assign({}, options, { db: sessionStorage, keyPrefix: _getKeyPrefix(options, this._defaultConfig) });
        if (!_isStorageUsable(dbInfo.db)) {
            return Promise.reject();
        }
        this._dbInfo = dbInfo;
        return getSerializerPromise(this).then(function (serializer) {
            dbInfo.serializer = serializer;
        });
    }
    function clear(callback) {
        var _this = this;
        var promise = this.ready().then(function () {
            var _a = _this._dbInfo, store = _a.db, keyPrefix = _a.keyPrefix;
            for (var i = store.length - 1; i >= 0; i--) {
                var key_1 = store.key(i) || '';
                if (key_1.indexOf(keyPrefix) === 0) {
                    store.removeItem(key_1);
                }
            }
        });
        executeCallback(promise, callback);
        return promise;
    }
    function getItem(key, callback) {
        var _this = this;
        key = normalizeKey(key);
        var promise = this.ready().then(function () {
            var _a = _this._dbInfo, store = _a.db, keyPrefix = _a.keyPrefix, serializer = _a.serializer;
            var result = store.getItem(keyPrefix + key);
            if (!result) {
                return result;
            }
            return serializer.deserialize(result);
        });
        executeCallback(promise, callback);
        return promise;
    }
    function iterate(iterator, callback) {
        var _this = this;
        var promise = this.ready().then(function () {
            var _a = _this._dbInfo, store = _a.db, keyPrefix = _a.keyPrefix, serializer = _a.serializer;
            var keyPrefixLength = keyPrefix.length;
            var length = store.length;
            var iterationNumber = 1;
            for (var i = 0; i < length; i++) {
                var key_2 = store.key(i) || '';
                if (key_2.indexOf(keyPrefix) !== 0) {
                    continue;
                }
                var storeValue = store.getItem(key_2);
                var value = storeValue
                    ? serializer.deserialize(storeValue)
                    : null;
                var iteratorResult = iterator(value, key_2.substring(keyPrefixLength), iterationNumber++);
                if (iteratorResult !== void 0) {
                    return iteratorResult;
                }
            }
        });
        executeCallback(promise, callback);
        return promise;
    }
    function key(keyIndex, callback) {
        var _this = this;
        var promise = this.ready().then(function () {
            var _a = _this._dbInfo, store = _a.db, keyPrefix = _a.keyPrefix;
            var result;
            try {
                result = store.key(keyIndex) || null;
            }
            catch (error) {
                result = null;
            }
            if (!result) {
                return result;
            }
            return result.substring(keyPrefix.length);
        });
        executeCallback(promise, callback);
        return promise;
    }
    function keys(callback) {
        var _this = this;
        var promise = this.ready().then(function () {
            var _a = _this._dbInfo, store = _a.db, keyPrefix = _a.keyPrefix;
            var length = store.length;
            var keys = [];
            for (var i = 0; i < length; i++) {
                var itemKey = store.key(i) || '';
                if (itemKey.indexOf(keyPrefix) === 0) {
                    keys.push(itemKey.substring(keyPrefix.length));
                }
            }
            return keys;
        });
        executeCallback(promise, callback);
        return promise;
    }
    function length(callback) {
        var promise = this.keys().then(function (keys) { return keys.length; });
        executeCallback(promise, callback);
        return promise;
    }
    function removeItem(key, callback) {
        var _this = this;
        key = normalizeKey(key);
        var promise = this.ready().then(function () {
            var _a = _this._dbInfo, store = _a.db, keyPrefix = _a.keyPrefix;
            store.removeItem(keyPrefix + key);
        });
        executeCallback(promise, callback);
        return promise;
    }
    function setItem(key, value, callback) {
        var _this = this;
        key = normalizeKey(key);
        var promise = this.ready().then(function () {
            if (value === undefined) {
                value = null;
            }
            var originalValue = value;
            var _a = _this._dbInfo, store = _a.db, keyPrefix = _a.keyPrefix, serializer = _a.serializer;
            return new Promise(function (resolve, reject) {
                serializer.serialize(value, function (value, error) {
                    if (error) {
                        reject(error);
                        return;
                    }
                    try {
                        store.setItem(keyPrefix + key, value);
                        resolve(originalValue);
                    }
                    catch (e) {
                        if (e.name === 'QuotaExceededError' ||
                            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
                            reject(e);
                        }
                        reject(e);
                    }
                });
            });
        });
        executeCallback(promise, callback);
        return promise;
    }
    function dropInstance(dbInstanceOptions, callback) {
        callback = getCallback.apply(this, arguments);
        var options = (typeof dbInstanceOptions !== 'function' && dbInstanceOptions) || {};
        if (!options.name) {
            var currentConfig = this.config();
            options.name = options.name || currentConfig.name;
            options.storeName = options.storeName || currentConfig.storeName;
        }
        var promise;
        if (!options.name) {
            promise = Promise.reject('Invalid arguments');
        }
        else {
            try {
                var keyPrefix = !options.storeName
                    ? options.name + "/"
                    : _getKeyPrefix(options, this._defaultConfig);
                var store = this._dbInfo.db;
                for (var i = store.length - 1; i >= 0; i--) {
                    var key_3 = store.key(i) || '';
                    if (key_3.indexOf(keyPrefix) === 0) {
                        store.removeItem(key_3);
                    }
                }
                promise = Promise.resolve();
            }
            catch (e) {
                promise = Promise.reject(e);
            }
        }
        executeCallback(promise, callback);
        return promise;
    }
    var sessionStorageWrapper = {
        _driver: 'sessionStorageWrapper',
        _initStorage: _initStorage,
        _support: isSessionStorageValid(),
        iterate: iterate,
        getItem: getItem,
        setItem: setItem,
        removeItem: removeItem,
        clear: clear,
        length: length,
        key: key,
        keys: keys,
        dropInstance: dropInstance
    };

    return sessionStorageWrapper;

})));
